<template>
  <div>
    <!--  详情-->
    <div class="userdetail">
      <div class="smalldiv">
        <div class="smalltitle">姓&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp名</div>
        <div class="text">{{ user.realName }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">性&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp别</div>
        <div class="text">{{ user.sex == 0 ? "男" : "女" }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">手机号码</div>
        <div class="text">{{ user.contactPhone }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">毕业年份</div>
        <div class="text">{{ user.graduateYear }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">高考省份</div>
        <div class="text">{{ user.examProvince }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">户籍地区</div>
        <div class="text">
          {{ user.residenceProvince }}{{ user.residenceCity }}
        </div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">民&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp族</div>
        <div class="text">{{ user.nation }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">所在中学</div>
        <div class="text">{{ user.schoolName }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">是否色盲</div>
        <div class="text" v-if="user.colorWeakness == 0">否</div>
        <div class="text" v-if="user.colorWeakness == 1">是</div>
        <div class="text" v-if="user.colorWeakness == 2">未知</div>
      </div>
      <div class="edit">
        <div class="button" @click="goedit">编辑</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStudentDetail } from "@/api/personalCenter.js";
export default {
  data() {
    return {
      userId: "",
      user: {},
    };
  },
  mounted() {
    this.userId = this.$route.query.userId;
    this.getStudentDetail();
  },
  methods: {
    goedit(){
      this.$router.push({path:'/personalCenter/editPersonalInformation',query:{userId:this.userId}})
    },
    getStudentDetail() {
      getStudentDetail({ userId: this.userId }).then((res) => {
        if (res.code == 1) {
          this.user = res.data || {};
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.userdetail {
  margin-top: 55px;
  margin-left: 120px;
  .smalldiv {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    .smalltitle {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      text-align: left;
      width: 156px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .text {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
}
.edit {
  height: 300px;
  position: relative;
  .button {
    position: absolute;
    top: 80px;
    left: 400px;
    width: 150px;
    height: 32px;
    background: #1ab394;
    border-radius: 5px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    color: #fff;
  }
}
</style>
